import router from '../router'
import store from '../store'
import {hasToken} from "@/utils";
router.beforeResolve(async (to, from, next) => {
    console.log(to.path)

    if(to.path !== '/' && to.path !== '/404') {
        if(!store.state.user.token) {
            await store.dispatch('user/logout');
            next('/');
        }
        const tokenExists = hasToken();
        if (tokenExists) {
            next();
        } else {
            await store.dispatch('user/logout');
            next('/');
        }
    } if(to.path === '/') {
        if(store.state.user.token) {
            next('/dashboard');
        } else {
            next()
        }

    } else {
        console.log('test');
        next()
    }
})
